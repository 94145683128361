import S3 from "aws-sdk/clients/s3";

const options: any = {
    region: "us-west-2",
    credentials: {
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
    }
};
const folder = 'assets'

const METADATA = `${folder}/images-metadata.json`;

const client = new S3(options);

export default client;

export async function getMetadata() {
    const value = await client.getObject({
        Bucket: "steven-nosov-assets",
        Key: METADATA
    }).promise();

    const values: any = value.Body;
    return JSON.parse(new TextDecoder("utf-8").decode(values));
}

export async function getImage(Key: string) {
    const getResult = await client.getObject(({
        Bucket: "steven-nosov-assets",
        Key
    })).promise();

    const body: any = getResult?.Body;
    return URL.createObjectURL(new Blob([body], {type: `image/${Key.split('.')[1]}`}));
}