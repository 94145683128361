import React, {useEffect, useState} from 'react';
import ImageGallery from 'react-image-gallery';
import {Link, Element} from 'react-scroll'
import './App.css';
import './tailwind.generated.css';
import BC from './Assets/businesscard1.png'
import instagram from './Assets/ig.png'
import discord from './Assets/disc.png'
import twitter from './Assets/twt.png'
import art from './Assets/art.png'
import {getImage, getMetadata} from "./AWS";

const req: any = require;

function importAll(r: any) {
    return r.keys().map(r).map((e: any) => e.default);
}

const origin = '';//'https://steven-nosov-assets.s3-us-west-2.amazonaws.com';

// const images: string[] = importAll(req.context('./Assets/images', false, /\.(png|jpe?g|svg)$/));

function Main() {

    const [images, setImages] = useState();

    useEffect(() => {
        getMetadata().then((keys: string[]) => {
            Promise.all(keys.map(getImage)).then(setImages);
        }).catch(console.log);
    }, [])

    return (<>
            <div className="App">
                <div className='flex flex-col content-center text-white min-h-screen select-none p-3'>
                    <div
                        className='flex flex-1 flex-col content-center justify-center min-w-full justify-center space-y-6 text-4xl'>
                        <div className='flex content-center justify-center'>
                            <img src={BC}
                                 className="max-h-96 sm:h-full h-72 sm:w-full w-144 object-contain pointer-events-none"
                                 alt="logo"/>
                        </div>
                        <div className='flex items-center justify-center'>
                            <a className='transform ease-in-out duration-500 hover:scale-110' target="_top"
                               href='mailto:me@stevennosov.com'>me@stevennosov.com</a>
                        </div>
                        <div
                            className='flex items-center justify-center sm:space-x-6 sm:space-y-0 space-x-0 space-y-6 sm:flex-row flex-col'>
                            <a className='transform ease-in-out duration-500 hover:scale-110 h-24 w-24'
                               href="https://www.instagram.com/stevennosovart/">
                                <img className='h-full w-full object-contain pointer-events-none' src={instagram}
                                     alt='instagram'/>
                            </a>
                            <a className='transform ease-in-out duration-500 hover:scale-110 h-24 w-24'
                               href="https://discordapp.com/users/259106594044903431">
                                <img className='h-full w-full object-contain pointer-events-none' src={discord}
                                     alt='discord'/>
                            </a>
                            <a className='transform ease-in-out duration-500 hover:scale-110 h-24 w-24'
                               href="https://twitter.com/stevennosov">
                                <img className='h-full w-full object-contain pointer-events-none' src={twitter}
                                     alt='twitter'/>
                            </a>
                        </div>
                        <div className='flex flex-col items-center'>
                            <Link className='transform ease-in-out duration-500 hover:scale-110 h-24 w-24 cursor-pointer'
                                  to="art" spy={true} smooth={true} duration={500}>
                                <img className='h-full w-full object-contain pointer-events-none' src={art} alt='art'/>
                            </Link>
                        </div>
                    </div>
                </div>

            </div>

            {images &&
            <Element name="art">
                <ImageGallery thumbnailPosition='top'
                              showFullscreenButton={false}
                              showPlayButton={false}
                              items={images.map((e: any) => ({
                                  original: e,
                                  thumbnail: e,
                                  originalClass: 'flex items-center justify-center w-full'
                              }))}/>
            </Element>
            }
        </>
    );
}

export default Main;
