import React, {createRef, useEffect, useState} from 'react';
import Dropzone from 'react-dropzone'
import {DragDropContext, Draggable, DraggableId, Droppable} from 'react-beautiful-dnd';
import imageCompression from 'browser-image-compression';

import './App.css';
import './tailwind.generated.css';
import {v4 as uuid} from 'uuid';
import {GetObjectOutput, ListObjectsOutput, PutObjectRequest} from "aws-sdk/clients/s3";

import client, {getImage, getMetadata} from './AWS';

const folder = 'assets'

const METADATA = `${folder}/images-metadata.json`;

const grid = 8;

const uploadTypes = ['image/gif', 'image/png', 'image/jpeg'];

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
//
// async function getReturn(body: any) {
//
//     if (!body) {
//         return;
//     }
//
//
//     let reader: any;
//     if (body instanceof ReadableStream) {
//         reader = body.getReader();
//     } else {
//         reader = body;
//     }
//
//     let chunks: any[] = []
//
//     await new Promise((resolve) => {
//
//
//         reader.read().then(function processText({done, value}: any) {
//             if (done) {
//                 resolve();
//                 return;
//             }
//
//             chunks.push(value);
//             return reader.read().then(processText);
//         });
//     });
//
//     return chunks;
//     // return URL.createObjectURL(new Blob(chunks, { type }));
// }

function Admin() {

    const [metadata, setMetadata] = useState<string[]>();

    // Declare a new state variable, which we'll call "count"
    const [list, setList] = useState<{ items: any[], selected: any[] }>(
        {
            items: [],
            selected: []
        }
    );

    useEffect(() => {

        // client.getObject({
        //     Bucket: "steven-nosov-assets",
        //     Key: METADATA
        // }).promise()
        //     .then(async (value: GetObjectOutput) => {
        //
        //         const values: any = value.Body;// await getReturn(value.Body);
        //         const metadata = JSON.parse(new TextDecoder("utf-8").decode(values));
        //
        //         setMetadata(metadata)
        //     }).catch(console.log);

        getMetadata().then(setMetadata).catch(console.log);


    }, []);

    useEffect(() => {
        if (!metadata) {
            return;
        }

        client.listObjects({
            Bucket: "steven-nosov-assets",
        }).promise().then(async (value: ListObjectsOutput) => {
            const promiseArrays: any[] = await value!.Contents!
                .filter((e: any) => e.Key !== METADATA && e.Key !== `${folder}/`)
                .map(async (e: any) => {

                    const image = await getImage(e.Key);

                    // const getResult = await client.getObject(({
                    //     Bucket: "steven-nosov-assets",
                    //     Key: e.Key
                    // })).promise();
                    //
                    // const body: any = getResult?.Body;
                    //
                    // const image = URL.createObjectURL(new Blob([body], {type: `image/${e.Key.split('.')[1]}`}));//URL.createObjectURL(new Blob(chunks, { type: `image/${e.Key.split('.')[1]}` }));
                    return ({
                        id: e.Key,
                        image
                    });
                });

            let items = await Promise.all(promiseArrays);
            const selected = [];
            for (let i = 0; i < metadata.length; i++) {
                let index = items.findIndex(item => item!.id === metadata[i]);
                if (index !== -1) {
                    selected.push(items[index]);
                    delete items[index];
                    items = items.filter(e => e?.id);
                }
            }

            setList({
                items,
                selected
            });
        });
    }, [metadata])

    const [dragging, setDragging] = useState(false);
    const [uploading, setUploading] = useState(false);

    const inputRef = createRef<HTMLInputElement>()

    const idLists = {
        temporary: 'items',
        upload: 'selected'
    };

    const getList = (id: 'temporary' | 'upload'): any[] => {
        // @ts-ignore
        return list?.[idLists[id]];
    }

    const move = (source: any, destination: any, droppableSource: any, droppableDestination: any): any => {
        const sourceClone: any[] = Array.from(source);
        const destClone: any[] = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);

        destClone.splice(droppableDestination.index, 0, removed);

        return {
            [droppableSource.droppableId]: sourceClone,
            [droppableDestination.droppableId]: destClone
        };
    };

    const onDragEnd = (result: any) => {
        const {source, destination} = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );

            let state: any = {items};

            if (source.droppableId === 'upload') {
                state = {selected: items};
            }

            setList({...list, ...state});
        } else {
            const result = move(
                getList(source.droppableId),
                getList(destination.droppableId),
                source,
                destination
            );

            setList({
                items: result.temporary,
                selected: result.upload
            });
        }
    };

    const onFileChange = (files: any) => {
        setUploading(true);

        if (uploadTypes.indexOf(files?.[0]?.type) === -1) {
            alert(`Only types allowed are ${uploadTypes}`);
            return;
        }


        const options = {
            maxSizeMB: 1,          // (default: Number.POSITIVE_INFINITY)
            // maxWidthOrHeight: number,   // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
            // onProgress: Function,       // optional, a function takes one progress argument (percentage from 0 to 100)
            // useWebWorker: boolean,      // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
            //
            // // following options are for advanced users
            // maxIteration: number,       // optional, max number of iteration to compress the image (default: 10)
            // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
            // fileType: string,           // optional, fileType override
            // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
        }

        imageCompression(files[0], options)
            .then((file: File) => {
                const image = URL.createObjectURL(file);
                const reader = new FileReader();
                reader.readAsDataURL(file);

                const id = uuid();
                const type = file.type.substring("image/".length);

                const key = `${folder}/${id}.${type}`;

                const tempList: any = {
                    items: [...(list?.items || []), {image, id: key}],
                    selected: list?.selected
                };

                reader.onloadend = async () => {

                    const val: string | ArrayBuffer = reader.result!;

                    if (typeof val == 'string') {
                        const base64modified = val.replace(new RegExp(`^data:${file.type};base64,`), '');

                        const buffer = Buffer.from(base64modified, 'base64');

                        const uploadParams: PutObjectRequest = {
                            Bucket: "steven-nosov-assets",
                            Key: key,
                            Body: buffer
                        };

                        await client.putObject((uploadParams)).promise();
                    }

                    setList(tempList);
                    setUploading(false);
                };
            });

    }


    const getListStyle = (isDraggingOver: any) => ({
        background: isDraggingOver ? 'lightblue' : 'lightgrey',
        padding: grid,
        width: 250
    });

    return (<>
            <div className="App">
                <Dropzone
                    noClick
                    onDragLeave={() => setDragging(false)}
                    onDragEnter={() => setDragging(true)}
                    onDrop={(acceptedFiles: any[]) => {
                        setDragging(false);
                        onFileChange(acceptedFiles)
                    }}
                >
                    {({getRootProps, getInputProps}: any) => (
                        <div {...getRootProps()} className='border-none border-0' tabIndex={undefined}>
                            <div
                                className={`fixed h-full w-full flex justify-center items-center transition duration-300 ease-in-out ${dragging || uploading ? 'opacity-100' : 'opacity-0'} ${uploading ? 'pointer-events-auto' : 'pointer-events-none'}`}>
                                <h1 className='absolute text-black text-6xl z-10'>{uploading ? 'Uploading, please wait!' : 'Upload file'}</h1>
                                <div className='absolute h-full w-full bg-white opacity-75'/>
                            </div>
                            <input {...getInputProps()} hidden/>
                            <div className='flex flex-col content-center text-white min-h-screen select-none p-3'>

                                <div className='flex flex-row'>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <div className='flex flex-1 justify-center'>
                                            <div>
                                                <h1 className='text-4xl'>Temporary</h1>
                                                <Droppable droppableId="temporary">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}>
                                                            {list?.items?.map((item: any, index: number) => (
                                                                <DraggableItem item={item} index={index}/>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                                <input type='file' accept={uploadTypes.join(', ')}
                                                       ref={inputRef} hidden onChange={(event) => {
                                                    onFileChange(event.target.files)
                                                }}/>
                                                <button className='text-2xl w-full'
                                                        onClick={() => inputRef.current?.click()}>+ add image
                                                </button>
                                            </div>
                                        </div>
                                        <div className='flex flex-1 justify-center'>
                                            <div>
                                                <h1 className='text-4xl'>To be uploaded</h1>
                                                <Droppable droppableId="upload">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}>
                                                            {list?.selected?.map((item: { id: DraggableId, content: any }, index: number) => (
                                                                <DraggableItem item={item} index={index}/>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                                <button className='text-2xl w-full' onClick={async () => {

                                                    const uploadParams = {
                                                        Bucket: "steven-nosov-assets",
                                                        Key: METADATA,
                                                        Body: JSON.stringify(list.selected.map(({id}) => id))
                                                    };
                                                    setUploading(true);
                                                    await client.putObject((uploadParams)).promise()
                                                    setUploading(false);
                                                }}>+ upload images
                                                </button>
                                            </div>
                                        </div>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    )}
                </Dropzone>

            </div>
        </>
    );
}

const DraggableItem = ({item, index}: { item: any, index: number }) => {
    return <Draggable
        key={item?.id}
        draggableId={item?.id}
        index={index}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                )}>
                {<img src={item?.image} alt={item?.id}/>}
            </div>
        )}
    </Draggable>
}

export default Admin;
