import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Main from './Main';
import Admin from './Admin';

function App() {

    return ( <Router>
        <div>
            <Switch>
                <Route path="/95f70ec1-22ef-4bf6-bd97-bf618e2a81be">
                    <Admin />
                </Route>
                <Route path="/">
                    <Main />
                </Route>
            </Switch>
        </div>
    </Router>);
}

export default App;
